import React, { useState, useEffect, forwardRef } from 'react';
import {
  Box,
  Container,
  Heading,
  Text,
  VStack,
  HStack,
  Table,
  Tbody,
  Tr,
  Td,
  Avatar,
  Badge,
  Button,
  useColorModeValue,
  Flex,
  Icon,
  SimpleGrid,
  Image,
  useBreakpointValue,
  Stat,
  StatLabel,
  StatNumber,
  Input,
  InputGroup,
  InputLeftElement,
  BoxProps,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react';
import { motion, AnimatePresence, isValidMotionProp } from 'framer-motion';
import { FiAward, FiZap, FiTarget, FiActivity, FiTool, FiBox, FiClock, FiShoppingBag, FiUsers, FiLayers, FiChevronLeft, FiChevronRight, FiTrendingUp, FiArrowLeft, FiSearch, FiAlertTriangle, FiRefreshCw, FiLoader, FiStar, FiMoon } from 'react-icons/fi';
import { BiSolidCrown } from "react-icons/bi";
import { get } from '../../../utils/apiUtils';
import { useNavigate, useParams, useLocation } from 'react-router-dom';

const MotionBox = motion(
  forwardRef<BoxProps, 'div'>((props, ref) => {
    const chakraProps = Object.fromEntries(
      Object.entries(props).filter(([key]) => !isValidMotionProp(key))
    );
    return <Box ref={ref as React.Ref<any>} {...chakraProps} />;
  }) as React.ForwardRefExoticComponent<BoxProps & React.RefAttributes<any>>
);

const MotionFlex = motion(
  forwardRef<BoxProps, 'div'>((props, ref) => {
    const chakraProps = Object.fromEntries(
      Object.entries(props).filter(([key]) => !isValidMotionProp(key))
    );
    return <Flex ref={ref as React.Ref<any>} {...chakraProps} />;
  }) as React.ForwardRefExoticComponent<BoxProps & React.RefAttributes<any>>
);

interface LeaderboardEntry {
  username: string;
  uuid: string;
  value: number;
}

interface ApiResponse {
  data: LeaderboardEntry[];
  pagination: {
    totalItems: number;
    totalPages: number;
    currentPage: number;
    pageSize: number;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
  };
}

const gameModes = {
  'survival-tecnico': {
    name: 'Survival Técnico',
    logo: 'https://i.ibb.co/GkqRWhr/956621194331844758.png',
    apiPrefix: 'st',
    categories: [
      { name: 'Encantamientos', icon: FiZap, endpoint: 'encantamientos' },
      { name: 'Bloques', icon: FiBox, endpoint: 'bloques' },
      { name: 'Tiempos', icon: FiClock, endpoint: 'tiempos' },
      { name: 'Tradeos', icon: FiShoppingBag, endpoint: 'tradeos' },
      { name: 'Mobs', icon: FiUsers, endpoint: 'mobs' },
      { name: 'Crafteos', icon: FiTool, endpoint: 'crafteos' },
    ]
  },
  'survival-fantasy': {
    name: 'Survival Fantasy',
    logo: 'https://i.ibb.co/980m8Mn/956684925690605578.webp',
    apiPrefix: 'sf',
    categories: [
      { name: 'Votos', icon: FiStar, endpoint: 'votos' },
      { name: 'Bloques', icon: FiBox, endpoint: 'bloques' },
      { name: 'Tiempos', icon: FiClock, endpoint: 'tiempos' },
      { name: 'Experiencias', icon: FiActivity, endpoint: 'experiencia' },
      { name: 'Mobs', icon: FiUsers, endpoint: 'mobs' },
      { name: 'Crafteos', icon: FiTool, endpoint: 'crafteos' },
    ]
  },
  'survival-horus': {
    name: 'Survival Horus',
    logo: 'https://i.ibb.co/tqCtLvC/956621193966923816.png',
    apiPrefix: 'sh',
    categories: [
      { name: 'Votos', icon: FiStar, endpoint: 'votos' },
      { name: 'Tiempos', icon: FiClock, endpoint: 'tiempos' },
      { name: 'Niveles', icon: FiAward, endpoint: 'niveles' },
      { name: 'Kills', icon: FiTarget, endpoint: 'kills' },
      { name: 'Mobs', icon: FiUsers, endpoint: 'mobs' },
      { name: 'Experiencias', icon: FiActivity, endpoint: 'experiencia' },
      { name: 'Crafteos', icon: FiTool, endpoint: 'crafteos' },
      { name: 'Bloques', icon: FiBox, endpoint: 'bloques' },
    ]
  },
  'survival-custom': {
    name: 'Survival Custom',
    logo: 'https://i.ibb.co/4ZYJ0R9X/cropped-image.webp',
    apiPrefix: 'sc',
    categories: [
      { name: 'Bloques', icon: FiBox, endpoint: 'bloques' },
      { name: 'Bosses', icon: FiUsers, endpoint: 'bosses' },
      { name: 'Crafteos', icon: FiTool, endpoint: 'crafteos' },
      { name: 'Misiones', icon: FiStar, endpoint: 'misiones' },
      { name: 'Mobs', icon: FiUsers, endpoint: 'mobs' },
      { name: 'Nivel', icon: FiActivity, endpoint: 'nivel' },
      { name: 'Tiempo', icon: FiClock, endpoint: 'tiempo' },
      { name: 'Trabajos', icon: FiTool, endpoint: 'trabajos' },
      { name: 'Votos', icon: FiStar, endpoint: 'votos' }
    ]
  }
};

const randomUUIDs = [
    'f328b2e6-1eb9-43c9-be6d-c2eea6df2e65',
    '9f9d2a04-c9b6-481e-8686-12ffc4870642',
    '7ca003dc-175f-4f1f-b490-5651045311ad',
    '1cb3a71c-2338-455e-ac20-90cf99f5cf6a'
  ];
  
  function isUUIDv4(uuid: string) {
    const uuidV4Regex = /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    return uuidV4Regex.test(uuid);
  }
  
  const getAvatarUUID = (userUUID: string) => {
    if (isUUIDv4(userUUID)) {
      return userUUID;
    }
    return randomUUIDs[Math.floor(Math.random() * randomUUIDs.length)];
  };

const getCurrentGameModeAndCategory = (gameMode: string | undefined, category: string | undefined) => {
  const currentGameMode = gameMode && gameModes[gameMode as keyof typeof gameModes] ? gameModes[gameMode as keyof typeof gameModes] : null;
  const currentCategory = currentGameMode ? (currentGameMode.categories.find(c => c.endpoint === category) || currentGameMode.categories[0]) : null;
  return { currentGameMode, currentCategory };
};

export default function LeaderboardStats() {
  const [leaderboardData, setLeaderboardData] = useState<LeaderboardEntry[]>([]);
  const [pagination, setPagination] = useState<ApiResponse['pagination'] | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [searchUsername, setSearchUsername] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  
  const navigate = useNavigate();
  const { gameMode, category } = useParams<{ gameMode: string; category: string }>();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const currentPage = parseInt(queryParams.get('page') || '1', 10);

  const { currentGameMode, currentCategory } = getCurrentGameModeAndCategory(gameMode, category);

  const bgColor = useColorModeValue('orange.50', 'gray.900');
  const bgGradient = useColorModeValue(
    'linear(to-br, orange.50, gray.50)',
    'linear(to-br, gray.900, gray.800)'
  );
  const cardBgColor = useColorModeValue('white', 'gray.800');
  const textColor = useColorModeValue('gray.800', 'white');
  const accentColor = useColorModeValue('orange.500', 'orange.400');
  const secondaryColor = useColorModeValue('yellow.400', 'yellow.300');
  const tertiaryColor = useColorModeValue('cyan.500', 'cyan.400');
  const hoverBg = useColorModeValue('orange.50', 'gray.700');
  const borderColor = useColorModeValue('gray.100', 'gray.700');
  const uuidColor = useColorModeValue('gray.500', 'gray.400');

  const isMobile = useBreakpointValue({ base: true, md: false });

  useEffect(() => {
    if (gameMode && category && currentGameMode && currentCategory) {
      fetchLeaderboardData(currentCategory.endpoint, currentPage);
    } else if (gameMode && category) {
      setError(`Modo de juego "${gameMode}" o categoría "${category}" no válidos.`);
      setIsLoading(false);
    }
  }, [gameMode, category, currentPage]);

  const fetchLeaderboardData = async (endpoint: string, page: number) => {
    const { currentGameMode, currentCategory } = getCurrentGameModeAndCategory(gameMode, category);
    if (!currentGameMode || !currentCategory) {
      setError('Modo de juego o categoría no válidos.');
      setIsLoading(false);
      return;
    }

    setIsSearching(false);
    setError(null);
    setIsLoading(true);

    try {
      const response = await get<ApiResponse>(`${currentGameMode.apiPrefix}/${endpoint}/paginated?page=${page}&pageSize=50&order=DESC&orderBy=value`);
      if (response.success && response.data) {
        setLeaderboardData(response.data.data);
        setPagination(response.data.pagination);
      } else {
        throw new Error(response.error || 'Failed to fetch leaderboard data');
      }
    } catch (error) {
      console.error('Error fetching leaderboard data:', error);
      setError('Error al cargar los datos del leaderboard. Por favor, intenta de nuevo más tarde.');
      setLeaderboardData([]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearch = async () => {
    const { currentGameMode, currentCategory } = getCurrentGameModeAndCategory(gameMode, category);
    if (!currentGameMode || !currentCategory) {
      setError('Modo de juego o categoría no válidos.');
      return;
    }

    if (searchUsername.trim() === '') {
      setError('Por favor, ingrese un nombre de usuario para buscar.');
      return;
    }

    setIsLoading(true);
    setIsSearching(true);
    setError(null);

    try {
      const response = await get<LeaderboardEntry>(`${currentGameMode.apiPrefix}/${currentCategory.endpoint}/username?username=${searchUsername}`);
      if (response.success && response.data) {
        setLeaderboardData([response.data]);
        setPagination(null);
      } else {
        setError(`No se encontraron resultados para el usuario "${searchUsername}" en la categoría "${currentCategory.name}" de ${currentGameMode.name}.`);
        setLeaderboardData([]);
      }
    } catch (error) {
      console.error('Error searching for user:', error);
      setError(`Error al buscar el usuario "${searchUsername}". Por favor, intenta de nuevo.`);
      setLeaderboardData([]);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePageChange = (newPage: number) => {
    navigate(`/leaderboard/${gameMode}/${currentCategory?.endpoint}?page=${newPage}`);
  };

  if (!gameMode || !category) {
    return (
      <Box bgGradient={bgGradient} minH="100vh" py={8} className="font-sans">
        <Container maxW="container.xl" px={4}>
          <Alert
            status="error"
            variant="subtle"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            height="auto"
            borderRadius="xl"
            py={8}
          >
            <AlertIcon boxSize="40px" mr={0} />
            <AlertTitle mt={4} mb={1} fontSize="lg">
              Error al cargar los datos
            </AlertTitle>
            <AlertDescription maxWidth="sm">
              Ruta no válida. Por favor, seleccione un modo de juego y categoría válidos.
            </AlertDescription>
            <VStack mt={6} spacing={4}>
              <Button
                onClick={() => navigate('/leaderboard')}
                leftIcon={<FiArrowLeft />}
                colorScheme="blue"
              >
                Volver al Leaderboard principal
              </Button>
            </VStack>
          </Alert>
        </Container>
      </Box>
    );
  }

  if (isLoading) {
    return (
      <Flex 
        justify="center" 
        align="center" 
        minHeight="100vh" 
        bg={bgGradient}
        direction="column"
        gap={8}
      >
        <MotionBox
          initial={{ scale: 0.5, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ duration: 0.5, repeat: Infinity, repeatType: "reverse" }}
        >
          <Icon as={FiLoader} boxSize={16} color={accentColor} />
        </MotionBox>
        <MotionBox
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.2, duration: 0.5 }}
        >
          <Text fontSize="xl" fontWeight="bold" color={textColor}>
            Cargando datos del leaderboard...
          </Text>
        </MotionBox>
      </Flex>
    );
  }

  if (error) {
    return (
      <Box bgGradient={bgGradient} minH="100vh" py={8} className="font-sans">
        <Container maxW="container.xl" px={4}>
          <Alert
            status="error"
            variant="subtle"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            height="auto"
            borderRadius="xl"
            py={8}
          >
            <AlertIcon boxSize="40px" mr={0} />
            <AlertTitle mt={4} mb={1} fontSize="lg">
              Error al cargar los datos
            </AlertTitle>
            <AlertDescription maxWidth="sm">
              {error || 'Modo de juego o categoría no válidos.'}
            </AlertDescription>
            <VStack mt={6} spacing={4}>
              <Button
                onClick={() => navigate('/leaderboard')}
                leftIcon={<FiArrowLeft />}
                colorScheme="blue"
              >
                Volver al Leaderboard principal
              </Button>
              <VStack align="stretch" width="100%" spacing={2}>
                <Text fontWeight="bold">Modos de juego disponibles:</Text>
                <SimpleGrid columns={2} spacing={2}>
                  {Object.entries(gameModes).map(([key, mode]) => (
                    <Button
                      key={key}
                      size="sm"
                      onClick={() => navigate(`/leaderboard/${key}/${mode.categories[0].endpoint}`)}
                    >
                      {mode.name}
                    </Button>
                  ))}
                </SimpleGrid>
              </VStack>
            </VStack>
          </Alert>
        </Container>
      </Box>
    );
  }

  return (
    <Box bgGradient={bgGradient} minH="100vh" py={8} className="font-sans">
      <Container maxW="container.xl" px={4}>
        <VStack spacing={8}>
          {/* Header Card */}
          <MotionBox
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            w="full"
          >
            <Flex 
              direction={{ base: "column", md: "row" }}
              align="center" 
              justify="space-between"
              gap={6}
              bg={cardBgColor}
              p={{ base: 4, md: 8 }}
              borderRadius="2xl"
              boxShadow="xl"
              border="1px solid"
              borderColor={borderColor}
              position="relative"
              overflow="hidden"
            >
              <Box
                position="absolute"
                top={0}
                left={0}
                right={0}
                h="4px"
                bgGradient={`linear(to-r, ${accentColor}, ${secondaryColor})`}
              />
              <HStack spacing={6} align="center" w={{ base: "full", md: "auto" }}>
                <Box position="relative" className="shrink-0">
                  <Image 
                    src={currentGameMode?.logo}
                    alt={`${currentGameMode?.name} Logo`} 
                    boxSize={{ base: "80px", md: "100px" }}
                    objectFit="contain"
                  />
                  <Box
                    position="absolute"
                    inset={0}
                    borderRadius="2xl"
                    bg={`linear-gradient(45deg, ${accentColor}20, transparent)`}
                  />
                </Box>
                <VStack spacing={2} align={{ base: "center", md: "start" }} w="full">
                  <Heading 
                    as="h1" 
                    size={{ base: "xl", md: "2xl" }}
                    textAlign={{ base: "center", md: "left" }}
                    color={textColor}
                    className="font-black tracking-tight"
                    bgGradient={`linear(to-r, ${textColor}, ${accentColor})`}
                    bgClip="text"
                  >
                    {currentGameMode?.name}
                  </Heading>
                  <Text 
                    fontSize={{ base: "lg", md: "xl" }}
                    fontWeight="medium"
                    color={accentColor}
                    className="tracking-wide"
                  >
                    {currentCategory?.name} - {isSearching ? "Búsqueda" : `Página ${currentPage}`}
                  </Text>
                </VStack>
              </HStack>
              <Stat 
                textAlign={{ base: "center", md: "right" }}
                bg={hoverBg}
                p={4}
                borderRadius="xl"
                minW={{ base: "full", md: "200px" }}
              >
                <StatLabel fontSize="sm" textTransform="uppercase" letterSpacing="wider">
                  Total de Jugadores
                </StatLabel>
                <StatNumber 
                  fontSize={{ base: "3xl", md: "4xl" }}
                  fontWeight="black" 
                  color={accentColor}
                  bgGradient={`linear(to-r, ${accentColor}, ${secondaryColor})`}
                  bgClip="text"
                >
                  {pagination?.totalItems.toLocaleString() || "-"}
                </StatNumber>
              </Stat>
            </Flex>
          </MotionBox>

          {/* Navigation */}
          <Flex w="full" justify="space-between" align="center">
            <Button
              leftIcon={<FiArrowLeft />}
              onClick={() => navigate('/leaderboard')}
              variant="ghost"
              size="lg"
              color={accentColor}
              _hover={{ 
                bg: hoverBg,
                transform: 'translateX(-4px)'
              }}
              transition="all 0.2s"
            >
              Volver al Leaderboard
            </Button>
          </Flex>

          {/* Category Buttons */}
          <Box
            width="full"
            sx={{ padding: '1rem 0', display: 'inline-flex', overflow: 'scroll' }}
          >
            {currentGameMode?.categories.map((cat) => (
              <MotionBox
                key={cat.name}
                margin="0 .5rem"
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
              >
                <Button
                  onClick={() => navigate(`/leaderboard/${gameMode}/${cat.endpoint}`)}
                  variant={cat === currentCategory ? 'solid' : 'outline'}
                  size="lg"
                  width="full"
                  py={7}
                  bg={cat === currentCategory ? accentColor : 'transparent'}
                  color={cat === currentCategory ? 'white' : textColor}
                  borderColor={cat === currentCategory ? accentColor : borderColor}
                  _hover={{ 
                    transform: 'translateY(-2px)',
                    boxShadow: 'lg',
                    borderColor: accentColor,
                    bg: cat === currentCategory ? accentColor : hoverBg
                  }}
                  transition="all 0.2s"
                  borderRadius="xl"
                  position="relative"
                  overflow="hidden"
                >
                  <VStack spacing={2} align="center">
                    <Icon as={cat.icon} boxSize={6} />
                    <Text fontSize={{ base: "xs", md: "sm" }}>{cat.name}</Text>
                  </VStack>
                  {cat === currentCategory && (
                    <Box
                      position="absolute"
                      top={0}
                      left={0}
                      right={0}
                      h="2px"
                      bgGradient={`linear(to-r, ${secondaryColor}, transparent)`}
                    />
                  )}
                </Button>
              </MotionBox>
            ))}
          </Box>

          {/* Search Input */}
          <MotionBox
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            w="full"
          >
            <InputGroup size="lg">
              <InputLeftElement pointerEvents="none">
                <Icon as={FiSearch} color={accentColor} />
              </InputLeftElement>
              <Input
                placeholder="Buscar por nombre de usuario"
                value={searchUsername}
                onChange={(e) => setSearchUsername(e.target.value)}
                onKeyPress={(e) => e.key === 'Enter' && handleSearch()}
                borderColor={borderColor}
                _hover={{ borderColor: accentColor }}
                _focus={{ borderColor: accentColor, boxShadow: `0 0 0 1px ${accentColor}` }}
                borderRightRadius="0"
              />
              <Button
                onClick={handleSearch}
                colorScheme="orange"
                size="lg"
                borderLeftRadius="0"
              >
                Buscar
              </Button>
            </InputGroup>
          </MotionBox>

          {/* Leaderboard Table */}
          <MotionBox
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.3 }}
            width="full"
          >
            <Box 
              bg={cardBgColor} 
              borderRadius="2xl" 
              boxShadow="xl"
              overflow="hidden"
              border="1px solid"
              borderColor={borderColor}
            >
              <Box 
                bg={accentColor}
                p={6}
                position="relative"
                overflow="hidden"
              >
                <Box
                  position="absolute"
                  top={0}
                  left={0}
                  right={0}
                  bottom={0}
                  bgGradient={`linear(to-r, ${accentColor}, ${secondaryColor})`}
                  opacity={0.9}
                />
                <Flex align="center" gap={3} position="relative" zIndex={1}>
                  <Icon as={BiSolidCrown} color="white" boxSize={6} />
                  <Heading as="h2" size="lg" color="white" className="font-black tracking-tight">
                    {isSearching ? "Resultado de búsqueda" : `Top 50 - ${currentCategory?.name}`}
                  </Heading>
                </Flex>
              </Box>
              
              <Box overflowX="auto">
                <Table variant="simple" w="full">
                  <Tbody>
                    <AnimatePresence>
                      {leaderboardData.map((entry, index) => (
                        <MotionFlex
                          key={entry.uuid}
                          as={Tr}
                          initial={{ opacity: 0, y: 20 }}
                          animate={{ opacity: 1, y: 0 }}
                          exit={{ opacity: 0, y: -20 }}
                          transition={{ duration: 0.3, delay: index * 0.03 }}
                          position="relative"
                          _hover={{ bg: hoverBg }}
                          className="group transition-all duration-300 ease-in-out"
                        >
                          <Td 
                            width={{ base: "30%", md: "20%" }}
                            px={{ base: 2, md: 6 }}
                            py={4}
                          >
                            <HStack spacing={3}>
                              <Badge 
                                colorScheme={index < 3 ? 'yellow' : 'gray'} 
                                variant={index < 3 ? 'solid' : 'subtle'}
                                px={3}
                                py={1.5}
                                borderRadius="full"
                                fontSize="sm"
                                fontWeight="bold"
                              >
                                #{isSearching ? "-" : (index + 1 + (currentPage - 1) * 50)}
                              </Badge>
                              {index < 3 && !isSearching && (
                                <Icon 
                                  as={FiAward} 
                                  color={secondaryColor}
                                  boxSize={5}
                                  className="transform group-hover:scale-110 transition-transform"
                                />
                              )}
                            </HStack>
                          </Td>
                          <Td 
                            width={{ base: "70%", md: "60%" }}
                            px={{ base: 2, md: 6 }}
                            py={4}
                          >
                            <Flex 
                              align="center" 
                              gap={4}
                            >
                              <Avatar 
                                size={{ base: "sm", md: "md" }}
                                name={entry.username} 
                                src={`https://crafatar.com/avatars/${getAvatarUUID(entry.uuid)}?size=48&overlay=true`}
                                loading="lazy"
                                className="border-2 border-gray-200 dark:border-gray-700 transform group-hover:scale-105 transition-transform"
                              />
                              <VStack 
                                align="start" 
                                spacing={1}
                                minWidth={{ base: "120px", md: "200px" }}
                              >
                                <Text 
                                  fontWeight="bold" 
                                  fontSize={{ base: "sm", md: "lg" }}
                                  color={textColor}
                                  className="tracking-wide"
                                >
                                  {entry.username}
                                </Text>
                                <Text 
                                  fontSize="xs" 
                                  color={uuidColor} 
                                  className="font-mono hidden md:block"
                                >
                                  {entry.uuid}
                                </Text>
                              </VStack>
                            </Flex>
                          </Td>
                          <Td 
                            width={{ base: "30%", md: "20%" }}
                            isNumeric 
                            px={{ base: 2, md: 6 }}
                            py={4}
                          >
                            <HStack justify="flex-end" spacing={3}>
                              <Icon 
                                as={FiTrendingUp} 
                                color={tertiaryColor} 
                                boxSize={5}
                                className="transform group-hover:translate-y-[-2px] transition-transform hidden md:block"
                              />
                              <Text 
                                fontWeight="bold" 
                                fontSize={{ base: "sm", md: "xl" }}
                                color={tertiaryColor}
                                className="tabular-nums"
                              >
                                {entry.value.toLocaleString()}
                              </Text>
                            </HStack>
                          </Td>
                        </MotionFlex>
                      ))}
                    </AnimatePresence>
                  </Tbody>
                </Table>
              </Box>
            </Box>
          </MotionBox>

          {/* Pagination */}
          {pagination && !isSearching && (
            <HStack justify="center" spacing={6} mt={6}>
              <Button
                leftIcon={<FiChevronLeft />}
                onClick={() => handlePageChange(currentPage - 1)}
                isDisabled={!pagination.hasPreviousPage}
                variant="outline"
                size="lg"
                px={8}
                borderColor={borderColor}
                color={textColor}
                _hover={{ 
                  transform: 'translateX(-4px)',
                  borderColor: accentColor,
                  bg: hoverBg
                }}
                transition="all 0.2s"
              >
                Anterior
              </Button>
              <Text 
                fontSize="lg" 
                fontWeight="medium"
                color={textColor}
                px={4}
                py={2}
                borderRadius="lg"
                bg={hoverBg}
              >
                Página {currentPage} de {pagination.totalPages}
              </Text>
              <Button
                rightIcon={<FiChevronRight />}
                onClick={() => handlePageChange(currentPage + 1)}
                isDisabled={!pagination.hasNextPage}
                variant="outline"
                size="lg"
                px={8}
                borderColor={borderColor}
                color={textColor}
                _hover={{ 
                  transform: 'translateX(4px)',
                  borderColor: accentColor,
                  bg: hoverBg
                }}
                transition="all 0.2s"
              >
                Siguiente
              </Button>
            </HStack>
          )}
          {isSearching && (
            <Button
              onClick={() => currentCategory && fetchLeaderboardData(currentCategory.endpoint, currentPage)}
              colorScheme="orange"
              size="lg"
              mt={6}
            >
              Volver al Leaderboard
            </Button>
          )}
        </VStack>
      </Container>
    </Box>
  );
}

