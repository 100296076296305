import React, { forwardRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { motion, AnimatePresence, isValidMotionProp, MotionProps } from 'framer-motion';
import {
  Box,
  Flex,
  Text,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  useColorModeValue,
  useBreakpointValue,
  Tooltip,
  HStack,
  VStack,
  Avatar,
  Divider,
  BoxProps,
} from '@chakra-ui/react';
import {
  FiHome,
  FiLayout,
  FiPhone,
  FiFileText,
  FiUsers,
  FiUserX,
  FiLink,
  FiLogIn,
  FiLogOut,
  FiChevronDown,
  FiMenu,
  FiX,
  FiSettings,
  FiList,
  FiKey,
  FiStar,
} from 'react-icons/fi';
import { useAdminStatus } from '../../utils/adminUtils';

export default function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { isAuthenticated, user, logout, login, discordId } = useAuth();
  const location = useLocation();
  const { isAdmin, isLoading } = useAdminStatus(discordId);

  const username = user?.user_metadata?.name || 'Usuario';
  const avatarUrl = user?.user_metadata?.avatar_url;

  const isDashboard = location.pathname.startsWith('/dashboard');

  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.700');
  const textColor = useColorModeValue('gray.800', 'gray.100');
  const hoverColor = useColorModeValue('orange.500', 'orange.300');

  const isMobile = useBreakpointValue({ base: true, md: false });

  const navItems = [
    { name: 'Panel de Control', path: '/dashboard', icon: FiLayout },
    { name: 'Leaderboard', path: '/leaderboard', icon: FiStar },
  ];

  const dashboardItems = [
    { name: 'Mis Números', path: '/dashboard?category=numbers', icon: FiPhone },
    { name: 'Mis Transcripts', path: '/dashboard?category=transcripts', icon: FiFileText },
  ];

  const adminItems = [
    { name: 'Todos los Números', path: '/dashboard/admin?category=adminNumbers', icon: FiList },
    { name: 'Todos los Transcripts', path: '/dashboard/admin?category=adminTranscripts', icon: FiFileText },
    { name: 'Personal', path: '/dashboard/admin?category=adminStaff', icon: FiUsers },
    { name: 'Kicks', path: '/dashboard/admin?category=adminKicks', icon: FiUserX },
    { name: 'Retires', path: '/dashboard/admin?category=adminRetires', icon: FiUserX },
    { name: 'Usuarios Vinculados', path: '/dashboard/admin?category=adminLinkedUsers', icon: FiLink },
    { name: 'Gestión de Tokens', path: '/dashboard/admin?category=tokenAdmin', icon: FiKey },
  ];

  const menuVariants = {
    open: { opacity: 1, y: 0, transition: { type: 'spring', stiffness: 300, damping: 30 } },
    closed: { opacity: 0, y: -20, transition: { duration: 0.2 } },
  };

  
  const MotionBox = motion(
    forwardRef<BoxProps, 'div'>((props, ref) => {
      const chakraProps = Object.fromEntries(
        Object.entries(props).filter(([key]) => !isValidMotionProp(key))
      );
      return <Box ref={ref as React.Ref<any>} {...chakraProps} />;
    }) as React.ForwardRefExoticComponent<BoxProps & React.RefAttributes<any>>
  );

  
  return (
    <MotionBox
      as="header"
      bg={bgColor}
      borderBottom="1px"
      borderColor={borderColor}
      position="sticky"
      top={0}
      zIndex={10}
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Flex
        maxW="container.xl"
        mx="auto"
        px={4}
        py={4}
        alignItems="center"
        justifyContent="space-between"
      >
        <Link to="/">
          <HStack spacing={2}>
            <Box boxSize="43px" borderRadius="md" overflow="hidden">
             <img src="https://minelatino.com/wp-content/uploads/2025/04/cropped-Logo-sin-fondo-.png" alt="MineLatino Logo" />

            </Box>
          </HStack>
        </Link>

        <HStack spacing={6}>
          {!isMobile && navItems.map((item) => (
            <Tooltip key={item.name} label={item.name} hasArrow>
              <Link to={item.path}>
                <IconButton
                  aria-label={item.name}
                  icon={<item.icon />}
                  variant="ghost"
                  color={location.pathname === item.path ? hoverColor : textColor}
                  _hover={{ color: hoverColor, transform: 'scale(1.1)' }}
                  transition="all 0.2s"
                />
              </Link>
            </Tooltip>
          ))}

          {isAuthenticated ? (
            <Menu>
              <MenuButton
                as={Button}
                rightIcon={<FiChevronDown />}
                variant="outline"
                _hover={{ bg: 'orange.50' }}
              >
                <HStack spacing={2}>
                  <Avatar size="sm" name={username} src={avatarUrl} />
                  {!isMobile && <Text>{username}</Text>}
                </HStack>
              </MenuButton>
              <MenuList>
                {isDashboard && (
                  <>
                    {dashboardItems.map((item) => (
                      <MenuItem key={item.name} as={Link} to={item.path} icon={<item.icon />}>
                        {item.name}
                      </MenuItem>
                    ))}
                    {isAdmin && (
                      <>
                        <Divider />
                        {adminItems.map((item) => (
                          <MenuItem key={item.name} as={Link} to={item.path} icon={<item.icon />}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </>
                    )}
                  </>
                )}
                <Divider />
                <MenuItem onClick={logout} icon={<FiLogOut />}>
                  Cerrar sesión
                </MenuItem>
              </MenuList>
            </Menu>
          ) : (
            <Button
              onClick={login}
              colorScheme="orange"
              variant="outline"
              leftIcon={<FiLogIn />}
              _hover={{ bg: 'orange.50' }}
            >
              {isMobile ? '' : 'Iniciar sesión'}
            </Button>
          )}

          {isMobile && (
            <IconButton
              aria-label="Open menu"
              icon={isMenuOpen ? <FiX /> : <FiMenu />}
              variant="ghost"
              onClick={() => setIsMenuOpen(!isMenuOpen)}
            />
          )}
        </HStack>
      </Flex>

      <AnimatePresence>
        {isMobile && isMenuOpen && (
          <MotionBox
            variants={menuVariants}
            initial="closed"
            animate="open"
            exit="closed"
            bg={bgColor}
            borderTop="1px"
            borderColor={borderColor}
            p={4}
          >
            <VStack spacing={4} align="stretch">
              {navItems.map((item) => (
                <Link key={item.name} to={item.path} onClick={() => setIsMenuOpen(false)}>
                  <Button
                    leftIcon={<item.icon />}
                    variant="ghost"
                    justifyContent="flex-start"
                    width="full"
                    color={location.pathname === item.path ? hoverColor : textColor}
                    _hover={{ color: hoverColor }}
                  >
                    {item.name}
                  </Button>
                </Link>
              ))}
              {isAuthenticated && isDashboard && (
                <>
                  <Divider />
                  {dashboardItems.map((item) => (
                    <Link key={item.name} to={item.path} onClick={() => setIsMenuOpen(false)}>
                      <Button
                        leftIcon={<item.icon />}
                        variant="ghost"
                        justifyContent="flex-start"
                        width="full"
                        color={textColor}
                        _hover={{ color: hoverColor }}
                      >
                        {item.name}
                      </Button>
                    </Link>
                  ))}
                  {isAdmin && (
                    <>
                      <Divider />
                      {adminItems.map((item) => (
                        <Link key={item.name} to={item.path} onClick={() => setIsMenuOpen(false)}>
                          <Button
                            leftIcon={<item.icon />}
                            variant="ghost"
                            justifyContent="flex-start"
                            width="full"
                            color={textColor}
                            _hover={{ color: hoverColor }}
                          >
                            {item.name}
                          </Button>
                        </Link>
                      ))}
                    </>
                  )}
                </>
              )}
            </VStack>
          </MotionBox>
        )}
      </AnimatePresence>
    </MotionBox>
  );
}