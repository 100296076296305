import React, { useState } from 'react';
import {
  Box,
  Container,
  Heading,
  Text,
  VStack,
  Image,
  Button,
  useColorModeValue,
  chakra,
  Grid,
  Flex,
  Icon,
} from '@chakra-ui/react';
import { motion, AnimatePresence } from 'framer-motion';
import { Link } from 'react-router-dom';
import { FiArrowRight } from 'react-icons/fi';

const MotionBox = chakra(motion.div);

const leaderboardCategories = [
  { 
    name: 'Survival Técnico', 
    logo: 'https://i.ibb.co/GkqRWhr/956621194331844758.png', 
    path: '/leaderboard/survival-tecnico/encantamientos', 
    description: 'Domina la técnica y la estrategia',
    bgImage: 'https://i.ibb.co/nMXhspC/a7c56e180115343-1-6504f9bcb758c.webp',
  },
  { 
    name: 'Survival Horus', 
    logo: 'https://i.ibb.co/Fm3Prr4/horus.webp', 
    path: '/leaderboard/survival-horus/votos', 
    description: 'Explora el mundo de Horus',
    bgImage: 'https://i.ibb.co/2nvNPSX/horus-lobby.webp',
  },
  { 
    name: 'Survival Fantasy', 
    logo: 'https://i.ibb.co/TvrQmZw/fantasy-logo.webp', 
    path: '/leaderboard/survival-fantasy/experiencias', 
    description: 'Sumérgete en un mundo de fantasy',
    bgImage: 'https://i.ibb.co/TR81dLt/fantasy.webp',
  },
  {
    name: 'Survival Custom',
    logo: 'https://i.ibb.co/4ZYJ0R9X/cropped-image.webp',
    path: '/leaderboard/survival-custom/bloques',
    description: 'Vive la experiencia custom',
    bgImage: ''
  }
];

const Leaderboard: React.FC = () => {
  const [hoveredCategory, setHoveredCategory] = useState<string | null>(null);
  const bgOverlay = useColorModeValue('rgba(255, 255, 255, 0.8)', 'rgba(0, 0, 0, 0.8)');
  const cardBg = useColorModeValue('rgba(255, 255, 255, 0.9)', 'rgba(0, 0, 0, 0.7)');
  const textColor = useColorModeValue('gray.700', 'gray.100');
  const headingColor = useColorModeValue('orange.500', 'orange.300');

  return (
    <Box 
      minHeight="100vh"
      width="100%"
      position="relative"
      overflow="hidden"
      py={20}
    >
      {/* Background Image Container */}
      <Box
        position="absolute"
        top={0}
        left={0}
        right={0}
        height="70vh"
        backgroundImage="url('https://i.ibb.co/nMXhspC/a7c56e180115343-1-6504f9bcb758c.webp')"
        backgroundSize="cover"
        backgroundPosition="center"
        backgroundRepeat="no-repeat"
        _after={{
          content: '""',
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          height: '100%',
          background: 'linear-gradient(to bottom, transparent 30%, var(--chakra-colors-orange-500) 100%)',
          opacity: 0.9,
        }}
      />

      {/* Orange Gradient Background */}
      <Box
        position="absolute"
        top="70vh"
        left={0}
        right={0}
        bottom={0}
        bg="orange.500"
      />

      {/* Content Overlay */}
      <Box
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        bgGradient="linear(to-b, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0.5) 100%)"
        backdropFilter="blur(4px)"
      />

      <Container maxW="container.xl" position="relative" zIndex={1}>
        <VStack spacing={16} align="stretch">
          <MotionBox
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, ease: "easeOut" } as any}
            textAlign="center"
          >
            <Heading 
              as="h1" 
              fontSize={{ base: "5xl", md: "7xl" }}
              mb={6}
              bgGradient="linear(to-r, orange.400, red.500)"
              bgClip="text"
              fontWeight="extrabold"
              letterSpacing="tight"
              textShadow="0 2px 4px rgba(0,0,0,0.1)"
              fontFamily="'Poppins', sans-serif"
            >
              Leaderboard MineLatino
            </Heading>
            <Text 
              fontSize={{ base: "xl", md: "2xl" }}
              color={textColor} 
              maxW="800px" 
              mx="auto"
              fontWeight="medium"
              textShadow="0 1px 2px rgba(0,0,0,0.05)"
              fontFamily="'Roboto', sans-serif"
            >
              Compite y alcanza la cima en nuestros diferentes modos de juego
            </Text>
          </MotionBox>

          <Grid 
            templateColumns={{ base: "1fr", md: "repeat(2, 1fr)", lg: "repeat(3, 1fr)" }} 
            gap={8}
          >
            <AnimatePresence>
              {leaderboardCategories.map((category, index) => (
                <MotionBox
                  key={category.name}
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -50 }}
                  transition={{ duration: 0.5, delay: index * 0.1 } as any}
                  whileHover={{ y: -10, transition: { duration: 0.2 } }}
                >
                  <Box
                    borderRadius="2xl"
                    overflow="hidden"
                    boxShadow="xl"
                    position="relative"
                    height="400px"
                    transition="all 0.3s"
                    transform={hoveredCategory === category.name ? 'scale(1.05)' : 'scale(1)'}
                    onMouseEnter={() => setHoveredCategory(category.name)}
                    onMouseLeave={() => setHoveredCategory(null)}
                  >
                    <Box
                      position="absolute"
                      top={0}
                      left={0}
                      right={0}
                      bottom={0}
                      bgImage={`url(${category.bgImage})`}
                      bgSize="cover"
                      bgPosition="center"
                      bgRepeat="no-repeat"
                      transition="all 0.3s"
                      transform={hoveredCategory === category.name ? 'scale(1.1)' : 'scale(1)'}
                    />
                    <Box
                      position="absolute"
                      top={0}
                      left={0}
                      right={0}
                      bottom={0}
                      bg={cardBg}
                      backdropFilter="blur(4px)"
                    />
                    
                    <Flex 
                      direction="column" 
                      position="relative"
                      height="100%"
                      justify="space-between"
                      p={8}
                    >
                      <Box>
                        <Flex justify="center" align="center" mb={6}>
                          <Image 
                            src={category.logo} 
                            alt={`${category.name} Logo`} 
                            boxSize="120px"
                            objectFit="contain"
                            transition="transform 0.3s"
                            transform={hoveredCategory === category.name ? 'scale(1.1) rotate(5deg)' : 'scale(1)'}
                          />
                        </Flex>
                        <Heading 
                          as="h3" 
                          fontSize="3xl"
                          color={textColor} 
                          mb={3} 
                          fontWeight="bold"
                          textShadow="0 1px 2px rgba(0,0,0,0.1)"
                          textAlign="center"
                          fontFamily="'Poppins', sans-serif"
                        >
                          {category.name}
                        </Heading>
                        <Text 
                          color={textColor} 
                          mb={6} 
                          fontSize="lg"
                          fontWeight="medium"
                          textAlign="center"
                          fontFamily="'Roboto', sans-serif"
                        >
                          {category.description}
                        </Text>
                      </Box>

                      <Button
                        as={Link}
                        to={category.path}
                        bg="rgba(255,255,255,0.2)"
                        color={textColor}
                        size="lg"
                        fontWeight="bold"
                        width="full"
                        height="60px"
                        fontSize="lg"
                        _hover={{
                          bg: 'rgba(255,255,255,0.3)',
                          transform: 'translateY(-2px)',
                          boxShadow: 'lg',
                        }}
                        _active={{
                          bg: 'rgba(255,255,255,0.4)',
                          transform: 'translateY(0)',
                          boxShadow: 'md',
                        }}
                        transition="all 0.2s"
                        borderRadius="full"
                        backdropFilter="blur(10px)"
                        border="1px solid"
                        borderColor="rgba(255,255,255,0.2)"
                        rightIcon={<Icon as={FiArrowRight} transition="transform 0.2s" className="group-hover:translate-x-1" />}
                        fontFamily="'Roboto', sans-serif"
                      >
                        Ver clasificación
                      </Button>
                    </Flex>
                  </Box>
                </MotionBox>
              ))}
            </AnimatePresence>
          </Grid>
        </VStack>
      </Container>
    </Box>
  );
}

export default Leaderboard;